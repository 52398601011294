<template>
  <div wari-wari-event>
    <PSection class="quick-menu-holder">
      <QuickMenu
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
        v-prx:fixed
      />
    </PSection>
    <MainVisual />
    <BasicReward />
    <FinalRewards />
    <ProceedWay />
    <OfficialVideo />
    <ParticipationRules />
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import MainVisual from '@/views/components/landing/wariwari/MainVisual.vue';
import BasicReward from '@/views/components/landing/wariwari/BasicReward.vue';
import FinalRewards from '@/views/components/landing/wariwari/FinalRewards.vue';
import ProceedWay from '@/views/components/landing/wariwari/ProceedWay.vue';
import OfficialVideo from '@/views/components/landing/wariwari/OfficialVideo.vue';
import ParticipationRules from '@/views/components/landing/wariwari/ParticipationRules.vue';
import QuickMenu from '@/views/components/landing/QuickMenu.vue';
import meta from '@/data/meta/index';

export default {
  name: 'WariWari',
  mixins: [gtag],
  components: {
    FinalRewards,
    BasicReward,
    MainVisual,
    ProceedWay,
    OfficialVideo,
    ParticipationRules,
    QuickMenu,
  },
  data() {
    return {
      quickMenu: {
        button: {
          img: '/img/pages/icon/discord.svg',
          text: '팀원 찾기',
        },
        menus: [
          {
            domId: 'main-visual',
            name: '대회 소개',
          },
          {
            domId: 'basic-reward',
            name: '대회 보상',
          },
          {
            domId: 'proceed-way',
            name: '대회 방식',
          },
          {
            domId: 'official-video',
            name: '홍보 영상',
          },
          {
            domId: 'participation-rules',
            name: '참고 사항',
          },
        ],
      },
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    discordConnect() {
      this.trackEvent('landing_discord_btn', 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/PkpEJxM98K');
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wari-wari-event] { .crop;
  .quick-menu-holder { .fix; .z(10);}

  @media (@ds-up) {
    .max-w(1920); .mh-c;
  }
}
</style>
