<template>
  <PSection wild-basic-reward id="basic-reward">
    <div v-que="{animation: 'fadeIn', delay: 0, duration: 500, ioRatio: 0.2}" class="character-obj">
      <img src="/img/event/wariwari/landing/Image/Lucian_Standard_Paintover.png" alt="Lucian">
    </div>
    <div class="basic-reward">
      <WrSectionTitle class="main-title" :title="title" />
      <div class="reward-holder">
        <BasicBox class="basic-box que" v-for="(item, id) in list" :key="id"
          v-que="{animation: 'fadeInSlowLeft', delay: (id * 200) + 150, duration: 500}" :item="item" />
      </div>
    </div>
  </PSection>
</template>

<script>
import que from '@/directives/que';
import BasicBox from '@/views/components/landing/wariwari/BasicBox.vue';
import WrSectionTitle from '@/views/components/landing/wariwari/WrSectionTitle.vue';

export default {
  name: 'BasicReward',
  directives: { que },
  components: { WrSectionTitle, BasicBox },
  data() {
    return {
      title: '단계 별 기본 보상',
      list: [
        {
          step: '1단계',
          main: '대회 참가 신청',
          imgUrl: '/img/event/wariwari/landing/Image/skin_and_kakao.png',
          reward: '랜덤 스킨 상자 1개,',
          subReward: '카카오웹툰 1만캐시 증정!',
          count: '( 상자 5,000명 / 캐시 2,500명 )',
        },
        {
          step: '2단계',
          main: '팀 구성 및 참가 완료',
          imgUrl: '/img/event/wariwari/landing/Image/Champion_Select.png',
          reward: '챔피언 상자 2개 증정!',
          subReward: '',
          count: '( 1인당 2개 )',
        },
        {
          step: '3단계',
          main: '1승 달성',
          imgUrl: '/img/event/wariwari/landing/Image/MobileSwagKit.jpg',
          reward: '모바일 선물세트 증정!',
          subReward: '',
          count: '( 1인당 1세트 )',
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import '~@/less/proj.less';
[wild-basic-reward] { .rel; .font-g; .pt(40); .pb(35);
  .character-obj { .abs; .lt(-315, 0); z-index: 1; .hide;
    > img { .w(799); }

    &.que-done { .t(-190); }
  }
  .basic-reward { .rel; z-index: 2;
    .main-title {
      &:after { .l(-29);}
    }
    .reward-holder {.w(285); .mh-c; .mt(38);
      .basic-box {
        &:nth-of-type(1) {
          &::v-deep .bottom {
            .reward { .wh(161, 80); .mb(3); .ml(40);}
            > em { .fs(14, 18.2px);}
          }
        }
      }
    }
  }
  @media (@tl-up) {
    .pt(80);
    .character-obj { .block; }
    .basic-reward {
      .main-title {
        &:after { .l(-75);}
      }
      .reward-holder { .w(776); .mt(52);
        .basic-box {
          &:nth-of-type(1) {
            &::v-deep > div.context-box { .pl(23);
              .bottom {
                .reward { .mb(12); .ml(12);}
                > p { .fs(11, 14.3px);}
              }
            }
          }
          &:nth-of-type(3) {
            &::v-deep > div.context-box { .pl(23);
              .bottom {
                > em { .fs(16, 20.8px); }
              }
            }
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .character-obj {
      > img { .w(942); }
    }
    .basic-reward {
      .reward-holder { .w(1095); .mt(52);
        .basic-box {
          &:nth-of-type(1) {
            &::v-deep > div.context-box { .pl(24);
              .bottom {
                .reward { .wh(232, 116); .mb(18); .ml(22);}
                > em { .fs(20, 26px);}
                > p { .fs(16, 20.8px);}
              }
            }
          }
          &:nth-of-type(3) {
            &::v-deep > div.context-box { .pl(33);
              .bottom {
                > em { .fs(24, 31.2px); }
              }
            }
          }
        }
      }
    }
  }
}
</style>
