<template>
  <PSection wild-proceed-way id="proceed-way" theme="white">
    <WrSectionTitle class="main-title" title="경기 진행 방식" />
    <div class="lussian-obj">
      <img src="/img/event/wariwari/landing/Image/Lucian_Action_Paintover.png" alt="Lucian">
    </div>
    <div class="senna-obj">
      <img src="/img/event/wariwari/landing/Image/Senna_Action_Paintover.png" alt="Senna">
    </div>
    <div class="context">
      <div v-que="{animation: 'fadeInUp', delay: 0, duration: 700}" class="league sub">
        <h6>
          예선
          <span>Bo 1</span>
        </h6>
        <div class="info">
          <div class="info-box">
            <span>7/31 (토)</span>
            <b>4팀 진출</b>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 700, duration: 500}" class="arrow"></span>
          <div class="info-box">
            <span>8/1 (일)</span>
            <b>4팀 진출</b>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 900, duration: 500}" class="arrow"></span>
          <div class="info-box">
            <span>8/2 (월)</span>
            <b>4팀 진출</b>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 1100, duration: 500}" class="arrow"></span>
          <div class="info-box">
            <span>8/3 (화)</span>
            <b>4팀 진출</b>
          </div>
        </div>
        <div class="result-info">
          <div>본선 진출팀 <b>16팀 선발</b></div>
        </div>
      </div>
      <div v-que="{animation: 'fadeInUp', delay: 0, duration: 700}" class="league main">
        <h6>
          본선
          <span>Bo 3</span>
        </h6>
        <div class="info">
          <div class="info-box big">
            <span>예선 통과</span>
            <b>16팀</b>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 700, duration: 500}" class="arrow"></span>
          <div class="info-group">
            <div class="info-box">
              <span class="left">승리</span>
              <b>8팀</b> 결선 진출
            </div>
            <div class="info-box">
              <span class="left">패배</span>
              <b>8팀</b> 재 대결
            </div>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 900, duration: 500}" class="arrow"></span>
          <div class="info-group">
            <div class="info-box">
              <span class="left">승리</span>
              <b>4팀</b> 결선 진출
            </div>
            <div class="info-box">
              <span class="left">패배</span>
              <b>4팀</b> 재 대결
            </div>
          </div>
          <span v-que="{animation: 'fadeIn', delay: 1100, duration: 500}" class="arrow"></span>
          <div class="info-box result">
            <span>승리</span>
            <b>2팀</b> 결선 진출
          </div>
        </div>
        <div class="result-info">
          <div>결선 진출팀 <b>14팀 선발</b></div>
        </div>
      </div>
      <div v-que="{animation: 'fadeInUp', delay: 0, duration: 700}" class="league final">
        <h6>
          결선
          <span>16강 토너먼트</span>
        </h6>
        <div class="info">
          <div class="top">
            <div>
              결선 진출팀 <b>14팀</b>
            </div>
            <div>
              스트리머 <b>2팀</b>
            </div>
          </div>
          <div class="bottom">
            8월 9일(월) ~ 24일(화) <b>매주 월, 화 생중계 진행</b>
          </div>
        </div>
        <div class="result-info">
          <div>상위 5팀 <b>한국대표 선발전 진출</b></div>
        </div>
      </div>
    </div>
  </PSection>
</template>

<script>
import que from '@/directives/que';
import WrSectionTitle from '@/views/components/landing/wariwari/WrSectionTitle.vue';

export default {
  name: 'ProceedWay',
  directives: { que },
  components: { WrSectionTitle },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-proceed-way] { .rel; .p(50, 0, 70); .bgc(#161e35); .crop; .mt(-2);
  [wr-section-title] { .mb(40);
    > div {
      .title { .c(#fff);
        &::before, &:after { .contain('/img/event/wariwari/landing/Icon/Title_element/Title_shape_sky.svg');}
      }
      > span { border-color: #69c6fa;
        &::before { border-top-color: #69c6fa; }
      }
    }
  }
  .lussian-obj, .senna-obj { .hide; }
  .lussian-obj { .abs; .rt(57vw, 0); z-index: 1; }
  .senna-obj { .abs; .lb(49vw, 252); z-index: 1; }
  .context { .rel; z-index: 2; .p(0, 4%);
    .league { .max-w(550); .m(32, 0); .mh-c; .c(white);
      &::before { .cnt; .wh(16, 24); .m(32, 0); .mh-c; .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_white_big.svg'); }
      &:first-child {
        &::before { .hide; }
      }
      &:last-child { .mb(0); }
      h6 { .max-w(135); .w(100%); .mh-c; .p(7, 0); .fs(20, 26); .bold; .c(white); .ls(-1); .font-g; .tc; .br(2.83);
        > span { .block; .mt(1); .fs(12, 15.6); .medium; }
      }
      .info {
        .info-box { .font-g;
          > b { .block; }
        }
      }
      .result-info { .br(4);
        > div { .p(23, 0); .fs(20, 26); .medium; .ls(-1); .font-g; .tc; .bg('/img/event/wariwari/landing/Logo/wildrift-hands-write.svg'); .no-repeat; .bg-h(100%); .bg-c; .bg-x(70%);
          &::before { content: ''; .rel; .ib; .wh(23.5, 30.16); .mt(-3); .mr(8); .contain('/img/event/wariwari/landing/Icon/WR_white_Logo.svg'); .bg-h(30.16); .bgc(transparent); .o(1); .vam; }
        }
      }

      &.sub {
        h6 { .bgc(#50ace0); }
        .info { .m(12, 6, 40); .crop;
          .info-box { width: calc(50% - 12px); .fl; .m(0, 6); .mt(20); .p(44, 0, 52); .tc; .-t(#69c6fa, 1.4); .-b(#69c6fa, 1.4); background: linear-gradient(rgba(105, 198, 250, 0) 75%, rgba(105, 198, 250, 0.5) 100%);
            > span { .block; .w(72); .mh-c; .mb(12); .fs(12, 24); .medium; .ls(-0.5); .tc; .bgc(#a38e40); .br(14); }
            > b { .fs(28, 36.4); .bold; .c(white); .ls(-1); }
          }
        }
        .result-info { background: linear-gradient(89.05deg, #7488f7 8.48%, #69c6fa 94.62%); }
      }
      &.main {
        h6 { .bgc(#ea6a65); }
        .info { .m(32, 6, 40); .crop;
          .info-group { display: inline;
            .info-box {
              &:last-child { filter: gray; -webkit-filter: grayscale(100%); }
            }
          }
          .info-box { .rel; .fl; width: calc(50% - 12px); .p(17, 0); .pl(15%); .m(0, 6); .fs(18, 23.4); .tc; .-t(#ea6a65, 1.4); .-b(#ea6a65, 1.4); background: linear-gradient(rgba(234, 106, 101, 0) 55%, rgba(234, 106, 101, 0.5) 100%);
            > span { .block; .w(42); .h(24); .mh-c; .mb(12); .fs(12, 24); .medium; .ls(-0.5); .tc; .bgc(#ea6a65); .br(14);
              &.left { .abs; .lt(5%, 28); z-index: 1; }
            }
            &.big { .block; width: calc(100% - 12px); .p(16, 0); .fs(28, 36.4); .-t(#ea6a65, 2); .-b(#ea6a65, 2); .tc;
              > span { .w(72); }
            }
            &.result { .block; width: calc(100% - 12px); .p(16, 0); .fs(28, 36.4); .-t(#ea6a65, 2); .-b(#ea6a65, 2); .tc;
              > span { .w(72); }
              > b { .ib; }
            }
          }
          .arrow { clear: both; .block; .wh(50%, 82); .mh-c; .bg-c;
            &:nth-of-type(1) { .wh(167, 36); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_1_m.svg'); }
            &:nth-of-type(2) { .wh(167, 42); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_2_m.svg'); }
            &:nth-of-type(3) { .wh(167, 36); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_m.svg'); }
          }
        }
        .result-info { background: linear-gradient(264.49deg, #ea6a65 10.54%, #c14641 85.49%); }
      }
      &.final {
        h6 { .bgc(#f0a845); }
        .info { .m(32, 6, 40);
          .top { .font-g; .mb(20); .crop; .contain('/img/event/wariwari/landing/Vector/ProceedWay/plus_white.svg'); .bg-c; .bg-s(14, 14);
            > div { .fl; width: calc(50% - 16px); .h(80); .p(14, 0); .fs(20, 26); .ls(-1); .c(#f0a845); .tc; .-a(#f0a845, 2);
              &:last-child { .fr; .c(#69c6fa); .-a(#69c6fa, 2);}
              > b { .block; .c(white); }
            }
          }

          .bottom { .font-g; .p(28, 0); .fs(24, 29.79); .c(#32c8ff); .tc; border: 2px dashed #f0a845; .ls(-1);
            > b { .block; .c(white); }
          }
        }
        .result-info { .font-g; background: linear-gradient(264.49deg, #f0a845 10.54%, #ca5929 85.49%); }
      }
    }
  }
  @media (@tl-up) {
    .p(100, 0, 172);
    [wr-section-title] { .mb(80); }
    .context {
      .league { .rel; .max-w(908); .mh-c; .mt(0); .mb(0); .p(0, 0);
        &::before { .m(35, 0); .mh-c; .wh(20, 29); }
        h6 { .fl; .h(225); .mr(14); .p(77, 20); .fs(28, 36.4);
          > span { .fs(18, 23.4); }
        }
        .info { .crop; }
        .result-info { .block; .w(100%); .mt(28); clear: both;
          > div { .fs(30, 39); .ls(-7%); .bg-x(53%);
            &::before { .wh(34.5, 45.16); .mt(-3); .mr(14);.bg-h(45.16); }
          }
        }
        &.sub {
          h6 { .h(168); .p(52, 0); }
          .info { .m(0, 0);
            .info-box { .w(156); .m(0, 0); .p(43, 0, 50); }
            .arrow { .fl; .block; .wh(30, 168); .m(0, 7.5); .bg('/img/event/wariwari/landing/Vector/ProceedWay/blue-arrow.svg'); .no-repeat; .bg-c; }
          }
        }
        &.main {
          h6 { .p(83, 20); }
          .info { .m(0, 0);
            > div { .fl; .w(156); }
            .info-group { .block;
              .info-box {
                &:first-child { .mb(24); }
              }
            }
            .arrow { clear: none; .fl; .wh(40, 160); .m(32, 2.5);
              &:nth-of-type(1) { .wh(40, 160); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_1_1920.svg'); }
              &:nth-of-type(2) { .wh(40, 160); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_2_1920.svg'); }
              &:nth-of-type(3) { .wh(40, 160); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_1920.svg'); .bg-y(82); }
            }
            .info-box { .w(156); .p(18, 0); .m(0, 0); .fs(22.7, 29.51);.ls(-7%);
              > span { .block;
                &.left { position: unset; .mb(8.5); }
              }
              > b { .ib; .mr(0); }
              &.big { .w(156); .p(74, 0); }
              &.result { float: none; .ib; .w(156); .m(62, 0); .p(18, 0); .fs(22.7, 29.51); .ls(-7%); .-t(#ea6a65, 1.4); .-b(#ea6a65, 1.4);
                > span { .w(42); .mb(8.5); }
              }
            }
          }
        }
        &.final {
          h6 { .p(70, 20); }
          .info { .m(0, 0);
            .top { .bg-s(16, 16);
              > div { width: calc(50% - 22px); .h(76.61); .p(20, 0); .fs(28, 36.4);
                > b { .ib; }
              }
            }

            .bottom { .p(32, 0); border: 1.4px dashed #f0a845;
              > b { .mt(4); }
            }
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .p(120, 0, 180);
    .lussian-obj, .senna-obj { .block; }
    .context { .max-w(1280); .p(0, 0); .mh-c;
      .league { .max-w(1280); .ml(0); .mr(0);
        &::before { .m(48, 0); .mh-c; .wh(30, 43.6); }
        h6 { .max-w(168); .h(236); .mr(40); .p(77, 20); .fs(42, 54.6); .ls(-7%);
          > span { .fs(24, 31.2);.ls(-7%); }
        }
        .result-info { .mt(40);
          > div { .p(33, 0); .fs(42, 54.6); .ls(-7%);
            &::before { .wh(43.5, 59.16); .mr(23); .bg-h(59.16); }
          }
        }
        &.sub {
          h6 { .h(236); .p(72, 0); }
          .info { .m(0, 0);
            .info-box { .w(220); .m(0, 0); .p(48, 0, 81);
              > span { .w(108); .mb(20); .fs(18, 32); .br(20); }
              > b { .fs(42, 54.6); .ls(-7%); }
            }
            .arrow { .wh(40, 236); .m(0, 12); }
          }
        }
        &.main {
          h6 { .h(318); .p(111, 20); }
          .info { .m(0, 0);
            > div { .fl; .w(220); }
            .info-group { .block;
              .info-box {
                &:first-child { .mb(42); }
              }
            }
            .arrow { clear: none; .fl; .wh(58, 258); .m(32, 2.5);
              &:nth-of-type(1) { .wh(58, 258); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_1_1920.svg'); }
              &:nth-of-type(2) { .wh(58, 258); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_gray_2_1920.svg'); }
              &:nth-of-type(3) { .wh(58, 258); .contain('/img/event/wariwari/landing/Vector/ProceedWay/Arrow_red_1920.svg'); .bg-y(100); }
            }
            .info-box { .w(220); .p(26, 0); .m(0, 0); .fs(32, 41.6); .ls(-7%);
              > span { .wh(60, 32); .fs(18, 32); .br(20);
                &.left { .mb(12); }
              }
              &.big { .w(220); .p(103, 0, 120); .fs(42, 54.6);.ls(-7%);
                > span { .w(108); }
              }
              &.result { .w(220); .m(62, 0); .p(26, 0); .fs(32, 41.6); .ls(-7%);
                > span { .w(60); .mb(12); }
              }
            }
          }
        }
        &.final {
          h6 { .h(318); .p(95, 20); }
          .info { .m(0, 0);
            .top { .bg-s(22, 22);
              > div { width: calc(50% - 32px); .h(108); .p(26.5, 0); .fs(42, 54.6);
              }
            }

            .bottom { .p(45, 0); .fs(36, 42); .ls(-7%); border: 2px dashed #f0a845;
              > b { .mt(8); }
            }
          }
        }
      }
    }
  }
}
</style>
