<template>
  <div wild-basic-box>
    <div class="fill">
    </div>
    <div class="line">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="context-box">
      <div class="content">
        <div class="top">
          <span class="step">{{ item.step }}</span>
          <p>{{ item.main }}</p>
          <span class="line"></span>
        </div>
        <div class="bottom">
          <img class="reward" :src="item.imgUrl">
          <em>{{ item.reward }}</em> <em v-if="item.subReward">{{ item.subReward }}</em>
          <p>{{ item.count }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BasicBox',
  props: {
    item: { type: Object, default: null },
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[wild-basic-box] { .rel; .mt(50); .wh(277.95, 216);
  > div.fill {
    .h(100%); .bgc(#ede8d9); .o(.9); .rel;
    &::before {
      content: ''; .abs; .wh(0); .lb(0, 0);
      border-bottom: 40px solid #fff;
      border-right: 40px solid transparent;
    }
  }
  > div.line {
    .wh(100%); .abs; .lt(0, 0); .crop;
    z-index: 2;
    transform: translate(8px, -8px);
    > span { .abs; .wh(1, 1); .bgc(#a38e40); z-index: 1;
      &:nth-of-type(1) { .lt(0, 0); .w(100%);}
      &:nth-of-type(2) { .rt(0, 0); .h(100%);}
      &:nth-of-type(3) { .rb(0, 0); width: calc(100% - 40px);}
      &:nth-of-type(4) { .lt(0, 0); height: calc(100% - 40px);}
      &:nth-of-type(5) { .lb(40, -33.5%); .h(67%);
        transform: rotate(-45deg);
      }
    }
  }
  > div.context-box { .abs; .lt(0, 0); .wh(100%); .p(15, 14, 15, 24);}
  .context-box {
    .top {
      .step { .vab; .mr(8); .br(2.7); .tc;.ib; .wh(53.65, 24.33); .bgc(#1a1d22); .c(white); .fs(12, 24.33px); .bold; .font-spoqa; }
      > p { .fs(20, 20.28); .bold; .c(black); .ib; .ls(-1); }
      .line { background-color: #a38e40; .wh(244, 1); .block; .mt(12); }
    }
    .bottom {
      .reward { .wh(88); .mb(9); .mt(10); .ml(75);}
      > em { .fs(18, 23.4px); .c(#375be7); .bold; .block; .tc; .ls(-1);}
      > p { .fs(12, 15.6px); .c(#5b5c61); .tc; .ls(-0.5);}
    }
  }
  &:nth-of-type(1) { .rel;
    &:after { .abs; .rb(120, -29) ; .cnt; .contain('/img/event/wariwari/landing/Vector/basicReward/Arrow_gold.svg'); .wh(29, 16.3); .t-r(90deg);}
  }
  &:nth-of-type(2) { .rel;
    &:after { .abs; .rb(120, -29); .cnt; .contain('/img/event/wariwari/landing/Vector/basicReward/Arrow_gold.svg'); .wh(29, 16.3); .t-r(90deg);}
  }
  &:nth-of-type(3) {
    .context-box {
      .bottom {
        .reward { .wh(158, 88); .ml(41);}
      }
    }
  }
  @media (@tl-up) {
    .mr(70); .wh(212, 280); .ib;
    > div.context-box { .p(18, 0, 10, 18);
      .top {
        .step { .block; .mb(17); .wh(54, 24);}
        > p { .fs(21, 20.28px); .block; }
        .line { .wh(179.8, 1); .mt(23.27);}
      }
      .bottom {
        .reward { .wh(100); .mb(6.7); .mt(12);.ml(42);}
        > em { .fs(18, 23.4px);}
        > p { .fs(12, 15.6px);}
      }
    }
    &:nth-of-type(1) { .rel;
      &:after { .t-r(0deg); .abs; .rb(-58, 130); .wh(40, 24); }
    }
    &:nth-of-type(2) { .rel;
      &:after { .t-r(0deg); .abs; .rb(-58, 130); .wh(40, 24);}
    }
    &:nth-of-type(3) { .mr(0);
      .context-box {
        .bottom {
          .reward { .wh(180, 100); .ml(0); }
        }
      }
    }
  }
  @media (@ds-up) {
    .wh(318, 420);
    > div.line {
      transform: translate(12px, -12px);}
    > div.context-box { .p(28, 0, 0, 28);
      .top {
        .step { .fs(16, 36px); .wh(80, 36); .mb(24);}
        > p { .fs(32, 30px); }
        .line { .w(266); .mt(35.5); }
      }
      .bottom {
        .reward { .wh(146); .mt(18); .mb(13.5); .ml(60);}
        > em { .fs(24, 31.2px);}
        > p { .fs(16, 20.8px);}
      }
    }
    &:nth-of-type(1) {
      &:after { .rb(-60, 193);}
    }
    &:nth-of-type(2) {
      &:after { .rb(-60, 193);}
    }
    &:nth-of-type(3) {
      .context-box {
        .bottom {
          .reward { .wh(266, 148); .mt(14.5);}
        }
      }
    }
  }
}
</style>
