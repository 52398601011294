<template>
  <PSection wild-final-rewards id="final-rewards" theme="white">
    <div v-que="{animation: 'fadeIn', delay: 500, duration: 1000}" class="sentinels-mark-obj">
      <img src="/img/event/wariwari/landing/Logo/WR_sentinels_logo.svg" alt="sentinels-mark">
    </div>
    <div v-que="{animation: 'fadeInSlowRight', delay: 500, duration: 1000}" class="senna-img-obj">
      <img src="/img/event/wariwari/landing/Image/Senna_Standard_Paintover.png" alt="Senna">
    </div>
    <div class="context">
      <WrSectionTitle class="main-title" :title="title" />
      <div class="main-reward">
        <div v-que="{animation: 'flipInX', delay: 500, duration: 700}" class="main">
          <h4>총상금 <b>2,500만원</b></h4>
        </div>
        <div class="reward-items">
          <div class="item">
            <span>1위</span>
            <p class="plus">+</p>
            <p v-que="{delay: 300}" class="reward">1,000만</p></div>
          <div class="item">
            <div class="box">
              <div class="item-content">
                <span>2위</span>
                <p class="plus">+</p>
                <p class="reward">400만</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="box">
              <div class="item-content">
                <span>3위</span>
                <p class="plus">+</p>
                <p class="reward">200만</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="box">
              <div class="item-content">
                <span>4위</span>
                <p class="plus">+</p>
                <p class="reward">100만</p>
              </div>
            </div>
          </div>
          <span v-que="{delay: 1000}" class="line"></span>
          <div class="item"><p class="detail">8강 진출팀 모두</p>
            <p class="plus">+</p>
            <p class="reward">100만</p>
          </div>
        </div>
      </div>
      <div class="sub-reward ">
        <div v-que="{animation: 'flipInX', delay: 400, duration: 700}" class="sub">
          <h4>상위 5팀</h4>
          <em>한국대표 선발전</em>
          <p>진출 시드권 지급!</p>
        </div>
        <img v-que="{animation: 'fadeIn', delay: 400, duration: 700}" src="/img/event/wariwari/Image/WR_Seed_card.png" class="card">
      </div>
      <span v-que="{delay: 500}" class="que"></span>
    </div>
  </PSection>
</template>

<script>
import que from '@/directives/que';
import WrSectionTitle from '@/views/components/landing/wariwari/WrSectionTitle.vue';

export default {
  name: 'FinalRewards',
  directives: { que },
  components: { WrSectionTitle },
  data() {
    return {
      title: '결선 보상',
    };
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[wild-final-rewards] { .rel; .bgc(#1a1d22); .min-h(500); .c(white); .font-g;
  &::before { content: ''; .abs; .lt(0, -1); .-l(transparent, 50vw); .-r(transparent, 50vw); .-t(#fff, 27); }
  .sentinels-mark-obj { .abs; .lt(0, -38); .r(0); z-index: 1; .tc;
    > img { .max-w(803); .min-w(300); .w(52%); }
  }
  .senna-img-obj { .abs; .rt(-542, 25); z-index: 2; .hide;
    > img { .w(918); }
  }
  .context { .rel; z-index: 3; .max-w(1280); .mh-c;
    .main-title { z-index: 2;
      &::v-deep .title { .mt(80); .c(white);}
      &:after { .w(219); .bg-c; .l(-50);}
    }
    .main-reward { .max-w(336); .mh-c;
      .main {
        > h4 { .fs(28, 36.4px); .mh-c; .mt(37); .mb(20); .ls(-7%); .tc;
          > b { .c(#33c6ff);}
        }
      }
      .reward-items { .crop;
        .item { .fl; width: calc((100% / 3) - 1%);
          .box { .rel; .w(100%); .block;
            &:before { .cnt; .h(77); .bgc(rgba(54, 56, 64, 0.7)); }
            &:after { .cnt; .-t((rgba(54,56,64,0.7)), 25.72); .-l(transparent, 30); .-box;}
            .item-content {.abs; .lt(0, 0); .r(0); .tc; .p(16, 0);
              > span { .block; .wh(42.8, 22.83); .bgc(#a38e40); .br(14.27); .fs(12, 22.83px); .tc; .mh-c; .mb(9.2);}
              .plus { .ml(0);}
            }
          }
          > span { .block; .wh(42.8, 22.83); .bgc(#a38e40); .br(14.27); .fs(12, 22.83px); .tc; .mh-c; .mt(19.26); .mb(9.2);}
          .plus { .c(#a38e40); .fs(22.83, 22.83px); .ib; .ml(6); .mr(3);}
          .reward { .fs(24, 24px); .bold; .ib; .ls(-7%);
          }
          &:nth-of-type(1) { float: none; .mh-c; .h(102.7); .rel; .-a(#a38e40); .mb(20.28); .bgc((rgba(54,56,64,0.7))); .max-w(336);.min-w(300); .w(95%);
            &:before { content: ''; .abs; .wh(0); .lb(-2, -1); .-b(#1a1d22, 40); .-r(transparent, 40); z-index: 500; }
            &:after { content: ''; .abs; .wh(0); .lb(0, 0); .-b(#a38e40, 40); .-r(transparent, 40); }
            .reward { .fs(28, 28px); .bold; .ib; visibility: visible !important; .ls(-7%); }
            .plus { .c(#a38e40); .fs(22.83, 22.83px); .ib; .ml(94.72); .mr(4);}
          }
          &:nth-of-type(3) { .m(0, 1.5%); }
          &:nth-of-type(5) { .wh(335, 60); .mt(20); .bgc((rgba(54,56,64,0.7))); .tc;
            .detail { .fs(18, 60px); .ib; .ls(-7%); }
            .plus { .ml(10); .mr(3);}
          }
        }
      }
    }
    .sub-reward { .mt(60); .fs(28, 36.4px); .tc; z-index: 2; .ls(-7%);
      .sub {
        > h4 { .c(#375be7); }
        > em { .bold; }
        > p { .c(#c89b3c); .bold;}
      }
      .card { .wh(335, 153); .mt(20);}
    }
    > span { .block; .mt(32); .-t(#a38e40, 1); .tc; .crop; .w(334.5); .mh-c; .pb(60); transform: scaleX(0); transition: transform 0.3s;
      &::before { .cnt; .w(128); .mh-c; .-t(#a38e40, 5); .-l(transparent, 3); .-r(transparent, 3); .-box; }
      &.que-done { transform: scaleX(1); }
    }
  }
  @media (@tl-up) {
    &:before { .-t(#fff, 85); }
    .sentinels-mark-obj { .t(-150);
      > img { .max-w(700); }
    }
    .senna-img-obj { .block; }
    .context {
      .main-title { .mt(60);
        &:after { .w(373); .l(-84);}
        &::v-deep .title { .mt(125);}
      }
      .main-reward { .max-w(754); .mh-c; .mt(60);
        .main {
          > h4 { .fs(40, 52px); .w(338); .mt(32); .mb(35);}
        ;
        }
        .reward-items {
          .item { .w;
            .box { .w(239.68);
              &:before { .h(77); }
              &:after { .-t((rgba(54,56,64,0.7)), 25.72); .-l(transparent, 30); }
              .item-content { .pt(20);}
            }
            > span { .wh(42.8, 22.83); .fs(12, 22.83px);}
            .plus { .ml(70);}
            .reward { .fs(28, 28px);}
            &:nth-of-type(1) { float: none; .mh-c; .mb(37.09);
              .reward { .rel;
                &:after { .cnt; .contain('/img/event/wariwari/landing/Vector/finalReward/line-01.svg'); .wh(513, 37); .abs; .lb(-197, -60); .o(0); transition: opacity 0.6s 0.5s; }
                &.que-done {
                  &:after { .o(1); }
                }
              }
            }
            &:nth-of-type(3) { .ml(17); .mr(17);}
            &:nth-of-type(5) { float: none; .wh(753.29, 59.92); .mt(0);}
          }
          .line { clear: both; .block; background-color: #a38e40; .wh(1, 28.53); .mh-c;}
        }
      }
      .sub-reward { .fs(40, 52px); .mt(100); .ls(-7%);
        .sub {
          > em { .ib; .mr(10);}
          > p { .ib;}
        }
        .card { .block; .wh(448, 204); .mt(12); .mh-c;}
      }
      > span { .-t(#a38e40, 2); .w(754); .pb(60); .pb(100);
        &::before { .cnt; .w(446); .mh-c; .-t(#a38e40, 8); .-l(transparent, 5); .-r(transparent, 5); .-box; }
      }
    }

  }
  @media (@ds-up) {
    .senna-img-obj { right: auto; .l(64vw);
      > img { .w(1082); }
    }
    .sentinels-mark-obj { .t(-170);
      > img { .max-w(803); .w(90%); }
    }
    .context {
      .main-reward { .max-w(1056); .mh-c; .mt(60);
        .main {
          > h4 { .fs(48, 62.4px); .w(405); .mb(40);}
        ;
        }
        .reward-items {
          .item {
            .box { .w(336);
              &:before { .h(119); }
              &:after { .-t((rgba(54,56,64,0.7)), 25); .-l(transparent, 30);}
              .item-content { .p(27, 0);
                > span { .wh(60, 32);.br(20); .fs(18, 32px);.mb(12);}
                .reward { .fs(40, 40px);}
                .plus { .wh(19, 32); .vam;}
              }
            }
            > span { .wh(60, 32px); .fs(18, 32px); .mb(12);}
            &:nth-of-type(1) { .wh(447, 144); .mh-c; .mb(51.5); .max-w(447);
              .plus { .fs(32, 32px); .ml(137);}
              .reward { .fs(40, 40px);
                &:after { .wh(720, 51.5); .lb(-280, -92);}
              }
            }
            &:nth-of-type(3) { .ml(24); .mr(24); }
            &:nth-of-type(5) { .wh(1056, 84);
              .detail { .fs(24, 85px); .vat;}
              .plus { .m(0, 5, 0, 20); .wh(19, 32);}
              .reward { .fs(40, 84px); .vam;}
            }
          }
        }
      }
      .sub-reward { .fs(48, 62.4px); .mt(147);
        .card { .wh(614, 280);}
      }
      > span { .w(1120); .pb(124);
        &:before { .w(556);}
      }
    }
  }
}
</style>
