<template>
  <PSection wild-official-video id="official-video">
    <WrSectionTitle class="main-title" title="홍보 영상" />
    <div class="video-con">
      <div class="out">
        <div class="in">
          <div class="video">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/miED7_LSAe0?autoplay=1&amp;loop=1&amp;mute=1&playlist=miED7_LSAe0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              controls="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div v-que="{delay:0}" class="btn-con">
      <ButtonRipple @click="toggleToChallenge">바로 도전하기</ButtonRipple>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import que from '@/directives/que';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import WrSectionTitle from '@/views/components/landing/wariwari/WrSectionTitle.vue';

export default {
  name: 'OfficialVideo',
  mixins: [gtag],
  directives: { que },
  components: { ButtonRipple, WrSectionTitle },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toggleToChallenge() {
      this.trackEvent(`landing_challenge_02`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/wr');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-official-video] { .p(42, 0, 59);
  [wr-section-title] { .mb(35); }
  .video-con { .max-w(500); .mh-c; .p(0); transform: none; box-sizing: content-box;
    .out { .rel; transform: none;
      &::before { .cnt; .max-h(252); .h(45vw); .bgc(#efeadd); }
      &::after { .cnt; .-t(#efeadd, 30); .-l(transparent, 30); }
      .in { .abs; .lt(0, 0); .r(0); z-index: 1; height: 100%; border: none; transform: none;
        &::before { .cnt; .hide; .abs; .rt(-20, -5); z-index: 2; .-t(#a38e40, 30); .-l(transparent, 30); .-r(transparent, 30); transform: rotate(225deg); }
        &::after { .cnt; .hide; .abs; .abs; .rt(-21, -6); z-index: 3; .-t(#fff, 30); .-l(transparent, 30); .-r(transparent, 30); transform: rotate(225deg); }
        .video { .abs; .lt(0, 0); .r(0); z-index: 1; .crop; transform: none; .h(100%); .bgc(transparent);
          &::before { .cnt; .hide; .abs; .rt(0, 0); z-index: 2; .-t(#fff, 30); .-l(transparent, 30); }
          &::after { .cnt; .max-h(380); .h(45vw); .bgc(transparent); }
          .videoImg { .abs; .lt(0.0); .r(0); .crop; z-index: 1; transform: none; .wh(100%); .bgc(transparent);}
          //
          //    >iframe{ .abs; .lt(0,0); z-index: 1; .wh(100%, 100%); }
          //}

          &::before { .cnt; .hide; .abs; .rt(0, 0); z-index: 2; .-t(#fff, 30); .-l(transparent, 30); }
          &::after { .cnt; .max-h(380); .h(45vw); .bgc(transparent); }
        }
      }
    }
  }
  .btn-con { .mt(45); .p(0, 20); .tc;
    > button { .max-w(319); .wh(100%, 56); .fs(16, 20); .bold; .c(#fff); .-a(#69c6fa); .br(4); .vam; background: linear-gradient(135deg, rgba(55, 91, 231, 1) 0%, rgba(93, 171, 245, 1) 31%, rgba(105, 198, 250, 0.9318102240896359) 40%, rgba(95, 177, 246, 1) 47%, rgba(55, 91, 231, 1) 100%); .bg-s(400%, 400%); .bg-xy(100%, 50%); transition: background-position 0.4s;
      &::before { content: ''; .rel; .ib; .wh(18.5, 24.16); .mt(-3); .mr(8); .contain('/img/event/wariwari/landing/Icon/WR_white_Logo.svg'); .bg-h(24.16); .bgc(transparent); .o(1); .vam; }
      &:hover { .bg-xy(0, 50%); }
    }
  }
  @media (@tl-up) {
    .p(100, 0, 120);
    .video-con { .max-w(840); .p(30); .pb(44); .pl(80); .t-xy(0, -20);
      .out { .t-xy(-65, 65);
        &::before { .max-h(423); }
        &::after { .-t(#efeadd, 50); .-l(transparent, 50); }
        .in { .-a(#a38e40, 1); .t-xy(65, -65);
          &::before { .block; .rt(-33, -8); .-t(#a38e40, 50); .-l(transparent, 50); .-r(transparent, 50); }
          &::after { .block; .rt(-34, -9); .-t(#fff, 50); .-l(transparent, 50); .-r(transparent, 50); }
          .video { .t-xy(-28, 20);
            &::before { .block; .-t(#fff, 50); .-l(transparent, 50); }
            // &::after{ .max-h(423); }
          }
        }
      }
    }
    .btn-con { .mt(60);
      > button { .max-w(360); .wh(100%, 68); .fs(20, 20);
        &::before { .wh(25, 34); .mt(-3); .mr(16); .bg-h(34); }
      }
    }
  }
  @media (@ds-up) {
    .p(100, 0, 140);
    .video-con { .max-w(959);
      .out {
        &::before { .max-h(490); }
        .in {
          .video {
            // &::after{ .max-h(490); }
          }
        }
      }
    }
    .btn-con { .mt(64); }
  }
}
</style>
