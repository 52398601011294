<template>
  <PSection wild-main-visual ref="mainVisual" id="main-visual" :class="{ crop: isTop }">
    <div class="visual-con">
      <div v-que="{animation: 'jello', delay: 1000, duration: 750}" class="logo-con" @click="logoJelloAnimation">
        <img src="/img/event/wariwari/landing/Logo/WR_main_logo.svg" alt="WariWari Main Logo" />
      </div>
      <h1 v-que="{animation: 'fadeInUp', delay: 100, duration: 750}" class="visual-main-text">
        총상금 <em>2,500만원</em>과<br /> 한국대표 선발전 <br /> <em>진출 시드권</em>을 잡아라!
      </h1>
      <ul v-que="{animation: 'fadeInUp', delay: 100, duration: 750}" class="league-date-info">
        <li>
          <span>예선</span>
          7월 31일(토) ~ 8월 3일(화)
        </li>
        <li>
          <span>본선</span>
          8월 5일(목)
        </li>
        <li>
          <span>결선</span>
          8월 9일(월) ~ 8월 24일(화)
        </li>
      </ul>
      <div v-que="{animation: 'fadeIn', delay: 600, duration: 750}" class="btn-con">
        <ButtonRipple @click="toggleGoChallenge">바로 도전하기</ButtonRipple>
      </div>
      <div class="scroll-down" @click="toNextSection">Scroll Down</div>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import que from '@/directives/que';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';

export default {
  name: 'MainVisual',
  directives: { que },
  components: { ButtonRipple },
  mixins: [gtag],
  data() {
    return {
      isTop: true,
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.isTop = window.scrollY < 10;
    });
  },
  methods: {
    toggleGoChallenge() {
      this.trackEvent('landing_challenge_01', 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/wr');
    },
    toNextSection() {
      const secondSection = this.$refs.mainVisual.$el.nextSibling;
      const targetScrollOffset = secondSection.offsetTop - (document.querySelector('header').clientHeight || 0);

      this.$scroll.scrollMove(targetScrollOffset, 1000);
    },
    logoJelloAnimation(e) {
      const { target } = e;
      target.classList.remove('jello');
      setTimeout(() => target.classList.add('jello'), 200);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-main-visual] { .rel; z-index: 0; .bgc(#1a1d22);
  &.crop { z-index: 2; }
  .visual-con { .rel; height: calc(100vh - 100px); .min-h(636); .pt(8vh); .bg('/img/event/wariwari/landing/Image/WR_main_bg.jpg'); .bg-h(100%); .bg-c;
    .logo-con { .tc; visibility: visible;
      > img { .max-h(154); .min-h(120); .h(15vh); .mr(-15); .pointer;
        &.jello { animation: jello 0.75s; }
      }
    }
    .visual-main-text { .m(3vh, 0, 3.5vh); .fs(28, 37); .bold; .c(#fff); .ls(-7%); .font-g; .tc;
      > em {
        &:nth-of-type(1) { .c(#33c6ff); }
        &:nth-of-type(2) { .c(#c89b3c); }
      }
    }
    .league-date-info { .w(226); .mh-c;
      > li { .mb(8); .fs(14, 16); .c(#fff);
        > span { .ib; .wh(52, 30); .mr(12); .fs(12, 30); .tc; .bgc(#5b5c60); .br(20);}
        &:last-child { .mb(0);
          > span { .bgc(#d13639); }
        }
      }
    }
    .btn-con { .rel; .mt(5vh); .p(0, 20); .tc;
      > button { .max-w(319); .wh(100%, 56); .fs(16, 20); .bold; .c(#fff); .-a(#69c6fa); .br(4); .vam; background: linear-gradient(135deg, rgba(55, 91, 231, 1) 0%, rgba(93, 171, 245, 1) 31%, rgba(105, 198, 250, 0.9318102240896359) 40%, rgba(95, 177, 246, 1) 47%, rgba(55, 91, 231, 1) 100%); .bg-s(400%, 400%); .bg-xy(100%, 50%); transition: background-position 0.4s;
        &::before { content: ''; .rel; .ib; .wh(18.5, 24.16); .mt(-3); .mr(8); .contain('/img/event/wariwari/landing/Icon/WR_white_Logo.svg'); .bg-h(24.16); .bgc(transparent); .o(1); .vam; }
        // &::after{ .cnt; .abs; .lt(0,0); z-index: 1; .wh(100%, 45); background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 48%, rgba(255, 255, 255, 0) 100%); transform:rotate(145deg) translateX(0%); animation:wr-btn-twincle 0.5s 3s infinite; }
        &:hover { .bg-xy(0, 50%); }
      }
    }
    .scroll-down { .pointer; .abs; .lb(0, 32); .r(0); z-index: 1; .fs(12, 16); .c(#a08d4d); .tc; animation: wr-bounce 1.5s infinite;
      &::after { content: ''; .block; .wh(24, 12); .mh-c; .mt(8); .contain('/img/event/wariwari/landing/Icon/Scrolldown.svg'); .bg-h(12); .bg-c; }
    }
  }
  @media (@tl-up) {
    .visual-con { .h(100vh); .min-h(860); .pt(80);
      .logo-con { .tc; .pt(8vh);
        > img { .max-h(279); .min-h(200); .h(25vh); .mr(-15); }
      }
      .visual-main-text { .m(4vh, 0, 4.5vh); .fs(42, 54.6);
        > br {
          &:nth-of-type(2) { .hide; }
        }
        > em {
          &:nth-of-type(1) { .c(#33c6ff); }
          &:nth-of-type(2) { .c(#c89b3c); }
        }
      }
      .league-date-info { .w(496);
        > li { .ib; .mb(16); .fs(16, 16);
          > span { .ib; .wh(72, 36); .fs(16, 36); .bold;}
          &:nth-of-type(2) { .ml(32); }
        }
      }
      .btn-con { .mt(6vh);
        > button { .max-w(360); .wh(100%, 68); .fs(20, 20);
          &::before { .wh(25, 34); .mt(-3); .mr(16); .bg-h(34); }
        }
      }
      .scroll-down { .lb(0, 62); .r(0); .fs(16, 16);
        &::after { .wh(30, 15); .mt(10); .bg-h(15); }
      }
    }
  }

  @keyframes wr-btn-twincle {
    0% {
      opacity: 0;
      transform: rotate(145deg) translateY(400%);
    }

    25% {
      opacity: 0.8;
      transform: rotate(145deg) translateY(0%);
    }

    50% {
      opacity: 0;
      transform: rotate(145deg) translateY(-400%);
    }

    75% {
      opacity: 0.8;
      transform: rotate(145deg) translateY(0%);
    }

    100% {
      opacity: 0;
      transform: rotate(145deg) translateY(400%);
    }
  }

  @keyframes bgreflect {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
