<template>
  <PItem wild-participation-rules id="participation-rules" theme="white">
    <div class="context">
      <h4>참여 시 꼭 확인하세요.</h4>
      <div class="detail">
        <em>대회 일정 참고사항</em>
        <ul>
          <li>대회 기간: 7월 31일(토)부터 8월 24일(화)까지 진행</li>
          <li>참가 신청 오픈: 7월 19일(월) 오후 2시</li>
          <li class="line-etc">
            예선: 7월 31일(토), 8월 1일(일), 8월 2일(월),
            <div class="tab">8월 3일(화) 오후 7시 – 총 4일</div>
          </li>
          <li>본선: 8월 5일(목) 오후 7시</li>
          <li class="line-etc">
            결선: 8월 9일(월), 8월 10일(화), 8월 16일(월), 8월 17일(화),
            <div class="tab">8월 23일(월), 8월 24일(화) – 총 6일</div>
          </li>
          <li>체크인: 대회 시작 1시간 전부터 50분간 진행</li>
          <li>대진표 공개: 체크인 마감 후 10분 내 대진표 생성 후 공개</li>
          <li>로스터 변경: 참가 신청 마감 이전까지 수정 가능</li>
        </ul>
        <em>예선 및 본선 진행 시 참고사항</em>
        <ul>
          <li>경기 장소: 전 경기 온라인 대회 진행</li>
          <li>경기 방식: 예선 토너먼트(단판제) / 본선 토너먼트(변형, 3판2선제)</li>
          <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인)</li>
          <li>매칭 기준: 레벨업 페이지 대진표 기능을 통한 랜덤 매칭</li>
          <li class="line-etc">
            경기 진영: 랜덤 배정(블루, 레드)으로 레벨업 페이지 내 대진표 상세에서
            <div class="tab2">확인 가능</div>
          </li>
          <li class="line-etc">
            경기 설정: 아시아 서버(최초 진입 시 선택 팝업에서는
            <div class="tab2">‘유럽 및 아시아’선택), 교차선택 모드</div>
          </li>
          <li>
            경기 진행
            <ul>
              <li>대진표 확인 후 대진표 상 좌측에 위치한 팀이 커스텀 매치를 <br />개설합니다.</li>
              <li>커스텀 매치 개설 후 대진표 우측에 위치한 팀을 초대하여 규정에 따라 <br />경기를 진행합니다.</li>
              <li>로스터 외 선수 참여 시 자동 탈락됩니다.</li>
            </ul>
          </li>
          <li>
            경기 종료 후 결과 입력
            <ul>
              <li>경기 후 승리팀은 결과 스크린샷을 사이트에 업로드합니다.</li>
              <li>패배팀은 상대 결과 스크린샷을 확인하고 결과를 승인합니다.</li>
              <li>상대방이 승인하지 않은 결과는 10분 후 자동 승인됩니다.<br />(운영진이 최종 확인할 예정입니다.)</li>
            </ul>
          </li>
        </ul>
        <em>결선 진행 시 참고사항</em>
        <ul>
          <li>경기 방식: 16강 토너먼트</li>
          <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인)</li>
          <li>매칭 기준: 대진표에 따른 매칭</li>
          <li class="line-etc">
            경기 설정: 아시아 서버(최초 진입 시 선택 팝업에서는
            <div class="tab2">‘유럽 및 아시아’ 선택), 교차선택 모드</div>
          </li>
          <li>16강 ~ 8강: 온라인 대회 진행. 방송 진행</li>
          <li>
            4강 ~ 결승: 오프라인 대회 진행(레벨업 레드 스튜디오). 방송 진행
            <div class="tab8">(코로나19 거리두기 단계에 따라 온라인으로 진행할 수 있습니다.)</div>
          </li>
          <li>한국대표 선발전 진출 조건: 결선 상위 5팀. 만 16세 이상</li>
        </ul>
        <em>보상 및 혜택 주의사항</em>
        <ul>
          <li>상금: 제세공과금은 수상자가 부담</li>
          <li class="line-etc">
            카카오웹툰 1만캐시: 레벨업지지 쪽지를 통해 쿠폰 코드 지급
            <div class="tab11">(예선 종료 후 8월 중 지급)</div>
          </li>
          <li class="line-etc">
            랜덤 스킨 & 챔피언 상자: [리그 오브 레전드: 와일드 리프트] 인게임에서
            <div class="tab10">직접 지급(7월 30일 ~ 31일 지급)</div>
          </li>
          <li class="line-etc">
            모바일 선물세트: 휴대폰 케이스, 스트랩, 그립톡으로 구성
            <div class="tab9">(9월 중 택배 배송 예정)</div>
          </li>
          <li>
            모바일 선물세트 휴대폰 케이스는 아래 명시된 휴대폰 기종 중 선택 가능<br />
            <div class="line-h">
              iPhone : 7, 7+, 8, 8+, SE2, X, XS, XS MAX, XR, 11, 11 Pro,<br class="line" />
              <div class="tab4">11 Pro MAX, 12 mini, 12, 12Pro, 12 Pro Max</div>
              <div class="tab5">Galaxy : S10, S10+, S10 5G, S20, S20+, S20 Ultra 5G, Note 10,</div>
              <div class="tab4">Note 10+, Note 20, Note Ultra 5G,</div>
              <div class="tab6">S21, S21+,</div>
              <div class="tab7">S21 Ultra 5G</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </PItem>
</template>

<script>
export default {
  name: 'ParticipationRules',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-participation-rules] {
  .rel; .bgc(#1a1d22); .min-h(500);
  .context { .rel; z-index: 2; .max-w(1280); .mh-c; .pt(60); .pb(120);
    > h4 { .c(white); .fs(20, 20px); .o(.8);.min-w(196); .font-g; .tc;
      &:after { .cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(20); .mb(18); .ml(10);}
    }
    .detail { .max-w(336); .tl; .mh-c;
      > em {.c(white); .o(.6); .fs(16, 32px); .block; .mt(16); .mb(6); .bold;}
      > ul { .pl(8); .fs(11, 21px);
        .line-etc { .lh(16);}
        > li { .regular; .o(.4); .c(white); list-style: '- ';
          .tab { .ml(26); }
          .tab2 { .ml(49);}
          .tab3 { .ml(114);}
          .tab4 { .ib; .ml(41);}
          .tab6 { .ib;}
          .tab7 { .ml(41);}
          .tab8 { .ml(52);}
          .tab9 { .ml(86); }
          .tab10 { .ml(110); }
          .tab11 { .ml(92); }
          .line-h { .lh(16);}
          > ul { .pl(10);
            > li { .fs(11, 16px); list-style: decimal; }
          }
        }
      }
    }
    &:after {.cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(24); .ml(10); }
  }
  @media (@tl-up) {
    .context { .pt(120); .pb(200);
      > h4 { .fs(32, 41.6px);
        &:after { width: calc(100% - 116px); .m(40, 0); .ml(58);}
      }
      .detail { .max-w(780);
        > em { .fs(24, 32px); .mt(40); .mb(12);}
        > ul { .pl(12);
          .line-etc { .lh(32);}
          > li { .fs(16, 32px);
            .tab { .ib; .ml(0);}
            .tab2 { .ib; .ml(0);}
            .tab3 { .ib; .ml(0);
              > br { .hide;}
            }
            .tab4 { .ib; .ml(0);
              > br { .hide;}
            }
            .tab5 { .ib;}
            .tab6 { .ml(61);
              > br { .hide;}
            }
            .tab7 { .ib; .ml(2);}
            .tab8 { .ml(76);}
            .tab9 { .ml(0); .ib; }
            .tab10 { .ml(0); .ib; }
            .tab11 { .ml(0); .ib;}
            .line-h { .lh(24); .ml(-6);}
            .line { .hide;}
            > ul {
              > li { .fs(16, 24px);
                > br { .hide;}
              }
            }
          }
        }
      }
      .line { .hide;}
      &:after { .mt(40); .ml(58); width: calc(100% - 116px); }
    }
  }
  @media (@ds-up) {
    .context {
      > h4 { .w(100%); .min-w(1080);
        &:after { width: calc(100%); .m(40, 0); }
      }
      .detail { .max-w(873);
        > ul {
          > li {
            .tab6 { .ib; .ml(0);}
            .tab7 { .ib;}
          }
        }
      }
      &:after { .mt(40); .ml(58); width: calc(100%); }
    }
  }
}

</style>
